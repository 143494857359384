import CryptoJS from "crypto-js";

export function encryptWithAES(text: string) {
	const passphrase = "shary";
	return CryptoJS.AES.encrypt(text, passphrase).toString();
}

export function decryptWithAES(ciphertext: any) {
  const passphrase = "shary";
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export function setToken(token: string) {
  const encryptedRole = encryptWithAES(token.toString());
	if (typeof window !== "undefined") {
		window.localStorage.setItem("key", encryptedRole);
	}
}


export async function getToken() {
	let token:any = ""
	if (typeof window !== "undefined") {
		token = window.localStorage.getItem("key");
	}
	if (token) {
		return decryptWithAES(token);
	}
}

export async function removeToken() {
	if (typeof window !== "undefined") {
		window.localStorage.removeItem("key");
	}
	return true
}

