import {requestHandler} from "../../utils/requestHandler";
import {url} from "../../apiVariables/urls";
import {IToggleWorkingDaysRequestData, IToggleWorkingDaysResponse, IUserResponse} from "./user.interface";

export type changeLanguageApi = {
	language: string;
}

export default class UserService {
	constructor() {}

	changeLanguage(data: changeLanguageApi) {
		return requestHandler({
			path: url.dashboard.user.changeLanguage,
			method: "PUT",
			protected: true,
			data: data,
			customErrorHandler: true
		})
			.then((res:any) => {
				localStorage.setItem("lang", data.language)
				return {
					status: true,
					message: "updated"
				}
			})
			.catch((err:any) => {
				return {
					status: false,
					message: err.response.data
				}
			})
	}

	getUserLanguage() {
		return localStorage.getItem("lang") || "de";
	}

	getUser(): Promise<IUserResponse> {
		return requestHandler({
			path: url.dashboard.user.getByToken,
			method: "GET",
			protected: true
		}).then((res: any) => {
			if (res && res.status === 200) {
				return {
					status: true,
					data: res.data
				};
			}
			return {status: false};
		}).catch((e: any) => {
			return {
				status: false
			};
		})
	}

	toggleWorkingDays(data: IToggleWorkingDaysRequestData): Promise<IToggleWorkingDaysResponse> {
		return requestHandler({
			path: url.dashboard.user.changeWorkingDays,
			method: "PUT",
			protected: true,
			data: data,
			customErrorHandler: true
		})
			.then(() => {
				return {status: true};
			})
			.catch((e: any) => {
				console.error(e);
				return {status: true};
			});
	}

}
