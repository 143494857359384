import {createSlice} from '@reduxjs/toolkit';

interface SocketState {
	socket: any;
}

const initialState: SocketState = {
	socket: undefined,
};

const socketSlice = createSlice({
	name: 'socket',
	initialState,
	reducers: {
		setSocket: (state:any, action: any) => {
			state.socket = action.payload.current
			return state
		}
	}
});

export const { setSocket }:any = socketSlice.actions;
export default socketSlice.reducer;
