import {appWithTranslation} from "next-i18next";
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import NextNProgress from "nextjs-progressbar";
import 'react-spring-bottom-sheet/dist/style.css';
import Script from 'next/script'
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleAnalytics from "../components/shared/GoogleAnalytics";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../redux/store';
import 'antd/dist/antd.css';
import {useRouter} from "next/router";
import * as fbq from '../lib/fpixel'
import {useEffect} from "react";

// Setup mocks server for tests
if (process.env.REACT_APP_ENV !== "production") {
	import("../mocks").then(({setupMocks}) => {
		setupMocks();
	})
}

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();

	useEffect(() => {
		// This pageview only triggers the first time (it's important for Pixel to have real information)
		fbq.pageview()

		const handleRouteChange = () => {
			fbq.pageview()
		}

		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	return <Provider store={store}>

		<Script
			id="fb-pixel"
			strategy="afterInteractive"
			dangerouslySetInnerHTML={{
				__html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
			}}
		/>

		{process.env.API_URL === "http://localhost:8000/"
			? <PersistGate loading={null} persistor={persistor}>
				<GoogleAnalytics />

				<Script
					strategy="afterInteractive"
					async
					src="https://www.googletagmanager.com/gtag/js?id=GTM-T8ZB7RK"
				/>

				<Script
					strategy="afterInteractive"
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-K97J0MH5VE"
				/>
				<Script strategy="afterInteractive" dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];
		  function gtag(){dataLayer.push(arguments);}
		  gtag('js', new Date());
		  gtag('config', 'G-K97J0MH5VE', {
		  	page_path: window.location.pathname,
		  });`
				}} />

				{process.env.REACT_APP_ENV === "production"
					? <Script
						strategy="afterInteractive"
						type="text/javascript"
						charSet="UTF-8"
						src="//cdn.cookie-script.com/s/89f601a48ddc3dac6d5fe5e62e9b0f6e.js"
					/>
					: <Script
						strategy="afterInteractive"
						type="text/javascript"
						charSet="UTF-8"
						src="//cdn.cookie-script.com/s/a3b1472ba6b8e2a99a9458830383d006.js"
					/>
				}
				<NextNProgress />
				<GoogleOAuthProvider clientId="944148946932-t2jnu8po9jgh60ro7gfrb3mu0giopm76.apps.googleusercontent.com">
					<Component {...pageProps} />
				</GoogleOAuthProvider>
			</PersistGate>
			: <PersistGate loading={null} persistor={persistor}>
				{() => (
					<>
						<GoogleAnalytics />

						<Script
							strategy="afterInteractive"
							async
							src="https://www.googletagmanager.com/gtag/js?id=GTM-T8ZB7RK"
						/>

						<Script
							strategy="afterInteractive"
							async
							src="https://www.googletagmanager.com/gtag/js?id=G-K97J0MH5VE"
						/>
						<Script strategy="afterInteractive" dangerouslySetInnerHTML={{
							__html: `window.dataLayer = window.dataLayer || [];
		  function gtag(){dataLayer.push(arguments);}
		  gtag('js', new Date());
		  gtag('config', 'G-K97J0MH5VE', {
		  	page_path: window.location.pathname,
		  });`
						}} />

						{process.env.REACT_APP_ENV === "production"
							? <Script
								strategy="afterInteractive"
								type="text/javascript"
								charSet="UTF-8"
								src="//cdn.cookie-script.com/s/89f601a48ddc3dac6d5fe5e62e9b0f6e.js"
							/>
							: <Script
								strategy="afterInteractive"
								type="text/javascript"
								charSet="UTF-8"
								src="//cdn.cookie-script.com/s/a3b1472ba6b8e2a99a9458830383d006.js"
							/>
						}
						<NextNProgress />
						<GoogleOAuthProvider clientId="944148946932-t2jnu8po9jgh60ro7gfrb3mu0giopm76.apps.googleusercontent.com">
							<Component {...pageProps} />
						</GoogleOAuthProvider>
					</>
				)}
			</PersistGate>
		}
	</Provider>
}

export default appWithTranslation(MyApp)

export async function getStaticProps({ locale }:any) {
	return {
		props: {
			...(await serverSideTranslations(locale, ["common"], null, ["en"])),
		},
	};
}
