export const url = {
	"auth": {
		"signup": "customer/auth/signup",
		"signupCompany": "customer/auth/signupCompany",
		"signin": "customer/auth/signin",
		"resendSignupEmail": "customer/auth/resendSignupEmail",
		"resendForgotEmail": "customer/auth/resendForgotEmail",
		"activate": "customer/auth/activate",
		"changeEmailConfirmation": "customer/auth/changeEmailConfirmation",
		"confirmDeactivation": "customer/user/deactivate",
		"reset": "customer/auth/resetPassword",
		"forgot": "customer/auth/forgot",
		"google": "customer/auth/google",
		"restoreAccount": "customer/auth/restoreAccount"
	},
	"home": {
		"post": {
			"get": "customer/post",
			"search": "customer/post/search",
			"pay": "customer/payments",
			"confirmPayment": "customer/payment/confirmPayment",
			"getNearby": "customer/post/nearby",
			"calculate": "customer/post/calculate",
			"discount": "customer/code",
			"getPostsForExplore": "customer/post/getPostsForExplore",
			"getByUserPath": "customer/post/userPath",
			"getByUserId": "customer/post/byUser",
			"updateIban": "customer/post/updateIban"
		},
		"checkout": {
			"create": "customer/checkout",
			"get": "customer/checkout"
		},
		"category": {
			"getPopular": "customer/category/popular",
			"getAll": "customer/category",
			"getOneByPath": "customer/category/byPath"
		},
		"subCategory": {
			"getByParentCategory": "customer/subCategory/parentPath",
			"getAll": "customer/subCategory",
		},
		"favourites": {
			"favourite": "customer/favourite"
		},
		"contact": {
			"contact": "customer/contact"
		},
		"report": {
			"create": "customer/articleReport"
		},
		"seoKeywords": {
			"getDescriptionByName": "customer/seoKeyword/getDescriptionByName"
		},
		"keywordsLanding": {
			"get": "customer/keywordsLanding",
			"getAll": "customer/keywordsLanding"
		},
		"user": {
			"byUserPath": "customer/user/path"
		},
		"twilio": {
			"phoneNumberIsProvidedAndValid": "customer/twilio/isValid",
			"sendVerificationSms": "customer/twilio/send",
			"verify": "customer/twilio/verify",
		}
	},
	"dashboard": {
		"user": {
			"getByToken": "customer/user",
			"sendRestoreEmail": "customer/user/sendRestoreEmail",
			"user": "customer/user",
			"changePassword": "customer/user/changePassword",
			"deactivate": "customer/user/sendDeactivateEmail",
			"updateBank": "customer/user/updateBank",
			"toggleMarketing": "customer/marketingEmailsUsers",
			"get": "customer/marketingEmailsUsers",
			"toggleNotifications": "customer/user/toggleNotifications",
			"changeLanguage": "customer/user/changeLanguage",
			"changeWorkingDays": "customer/user/changeWorkingDays"
		},
		"chat": {
			"conversations": "customer/conversation",
			"conversationsById": "customer/conversation/conversationId",
			"message": "customer/message"
		},
		"dashboard": {
			"calendar": "customer/dashboard/calendar",
			"cards": "customer/dashboard/cards",
			"lastWeek": "customer/dashboard/lastWeek",
			"top": "customer/dashboard/top",
			"roi": "customer/dashboard/roi",
			"postsCard": "customer/dashboard/postsCard",
			"earnedCard": "customer/dashboard/earnedCard",
			"ratingCard": "customer/dashboard/ratingCard"
		},
		"borrowed": {
			"get": "customer/order/rentedAsOwner",
			"getOne": "customer/order",
			"getNumberOfRequestedOrders": "customer/order/numberOfRequestedOrders",
			"approve": "customer/order/approve",
			"reject": "customer/order/reject",
			"complete": "customer/order/completeRent",
			"decline": "customer/order/declineByCustomer"
		},
		"rented": {
			"get": "customer/order/rentedAsRenter",
			"getOne": "customer/order"
		},
		"posts": {
			"create": "customer/post",
			"get": "customer/post/byUser",
			"getOne": "customer/post",
			"update": "customer/post",
			"changeActiveStatus": "customer/post/changeActiveStatus",
			"calendar": "customer/post/calendar",
			"delete": "customer/post"
		},
		"blockedDays": {
			"byPostPath": "customer/blockedDays/byPostPath",
			"removeDateByTitle": "customer/blockedDays/removeDateByTitle",
			"addByDateAndPath": "customer/blockedDays/addByDateAndPath",
			"addByDateForAll": "customer/blockedDays/addByDateForAll"
		},
		"rating": {
			"create": "customer/rating",
			"getAllAsOwner": "customer/rating/ownerRatingsOverview",
			"getAllAsRenter": "customer/rating/renterRatingsOverview",
			"getNotRatedOrdersForRenter": "customer/rating/getNotRatedOrdersForRenter"
		},
		"wishlist": {
			"get": "customer/favourite",
			"getAll": "customer/favourite/all"
		},
		"payments": {
			"createAccountLink": "customer/payments/createAccountLink"
		},
		"damage": {
			"create": "customer/damage"
		}
	},
	"general": {
		"file_read": "customer/files/read",
		"unsubscribe_email": "customer/marketingEmailsUsers/unsubscribe",
		"validations": {
			"vat": "customer/validation/vat",
			"iban": "customer/validation/iban"
		}
	}
}
