import { configureStore, combineReducers, Reducer } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from './userSlice';
import favouritesReducer from './favouritesSlice';
import numberOfRequestedOrdersReducer from './requestedOrdersSlice';
import socketReducer from './socketSlice';

export type RootState = ReturnType<typeof rootReducer>
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer: Reducer<any> = combineReducers({
	user: userReducer,
	favourites: favouritesReducer,
	numberOfRequestedOrders: numberOfRequestedOrdersReducer,
	socket: socketReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export { store, persistor };
