import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import UserService from "../services/User/user.service";
import {IUser} from "../services/User/user.interface";

interface CounterState {
	user: IUser | undefined;
}

const initialState: CounterState = {
	user: undefined,
};

export const fetchUserById = createAsyncThunk(
	'users/fetchByIdStatus',
	async () => {
		return new UserService().getUser().then((res) => {
			if (res.status) {
				return res.data
			}
		});
	}
)

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		clearUser: (state:any) => {
			state.user = undefined
			return state
		}
	},
	extraReducers: (builder) => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(fetchUserById.fulfilled, (state, action) => {
			// Add user to the state array
			state.user = action.payload
		})
	}
});

export const { clearUser }:any = userSlice.actions;
export default userSlice.reducer;
