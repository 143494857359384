import axios from "axios";
import {getToken} from "./cookie/token.cookie";
import { apiErrorHandler } from "./apiErrorHandler/apiErrorHandler";
import {developmentLink} from "../constants";

interface IRequest {
	path: string;
	method: "GET" | "POST" | "PUT" | "DELETE";
	data?: any;
	params?: any;
	showErrorNotification?: boolean;
	protected?: boolean;
	customErrorHandler?: boolean;
}

export type RequestHandlerType = {
	status: number | boolean;
	data: any;
	responseStatus?: number;
} | boolean

export async function requestHandler(props:IRequest):Promise<RequestHandlerType> {
  try {
  	let tokenData:any;
  	if (props.protected) {
      tokenData = await getToken()
    }
    const response = await axios({
      url: `${process.env.API_URL || developmentLink}${props.path}`,
      method: props.method,
      params: props.params,
      data: props.data,
      headers: props.protected ? { Authorization: `Bearer ${tokenData}` } : {},
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (e: any) {
	  if (props.customErrorHandler) {
		  return {
			  status: false,
			  data: e.response.data,
			  responseStatus: e.response.status
		  };
	  }
	  console.error(e)
	  return apiErrorHandler(e.response.status, e, props.showErrorNotification);
  }
}
