import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {requestHandler} from "../utils/requestHandler";
import {url} from "../apiVariables/urls";

interface RequestedOrdersState {
	numberOfRequestedOrders: any;
}

const initialState: RequestedOrdersState = {
	numberOfRequestedOrders: undefined,
};

export const fetchNumberOfRequestedOrders = createAsyncThunk(
	'',
	async () => {
		return await requestHandler({
			path: url.dashboard.borrowed.getNumberOfRequestedOrders,
			method: "GET",
			protected: true
		}).then((res: any) => {
			if (res && res.status === 200) {
				return res.data.requestedOrdersNumber
			}
		});
	}
)

const numberOfRequestedOrdersSlice = createSlice({
	name: 'numberOfRequestedOrders',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(fetchNumberOfRequestedOrders.fulfilled, (state, action) => {
			// Add user to the state array
			state.numberOfRequestedOrders = action.payload
		})
	}
});

export const {}:any = numberOfRequestedOrdersSlice.actions;
export default numberOfRequestedOrdersSlice.reducer;
