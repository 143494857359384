import {notification} from "antd";
import Router from 'next/router'
import { i18n } from 'next-i18next';
import {removeToken} from "../cookie/token.cookie";
i18n?.init()

export async function apiErrorHandler(status: number, error?: any, showErrorNotification?: boolean) {
	let previousPath = "";

	// If error comes from error page change path (needed for reload button in error page)
	if (window && window.location.pathname !== "/server-error" && previousPath !== "/error") {
		previousPath = window.location.pathname;
	}
	if (status === 404 && window && window.location.pathname !== "/error") {
		Router.push(`404${previousPath ? `?pathname=${previousPath}` : ""}`);
		return true;
	} else if (status === 500 || status === 0 && window && window.location.pathname !== "/server-error") {
		Router.push(`500${previousPath ? `?pathname=${previousPath}` : ""}`);
		return true;
	} else if (status === 401) {
		await removeToken();
		Router.push("/auth/signin");
		return true;
	} else {
		switch (error.response.status) {
			case 400:
				notification.error({
					message: i18n?.t("errors.notificationTitles.internal").toString(),
					description: error.response.data?.error?.details[0]?.message
				});
				return {
					status: false,
					data: error.response.data?.error?.details[0]?.message
				};
			case 422:
				if (showErrorNotification) {
					notification.error({
						message: i18n?.t("errors.notificationTitles.validation").toString(),
						description: i18n?.t(`errors.messages.${error.response.data.message}`).toString(),
					});
				}
				return {
					status: false,
					data: error.response.data.message,
					fullData: error.response.data
				};
			default:
				return {
					status: false,
					data: error.response.data,
					fullData: error.response.data
				}
		}
		return false;
	}
}
