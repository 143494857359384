import {createSlice} from '@reduxjs/toolkit';

interface FavouritesState {
	favourites: any;
}

const initialState: FavouritesState = {
	favourites: undefined,
};

const favouritesSlice = createSlice({
	name: 'favourites',
	initialState,
	reducers: {
		setFavourites: (state:any, action: any) => {
			state.favourites = action.payload
			return state
		}
	}
});

export const { setFavourites }:any = favouritesSlice.actions;
export default favouritesSlice.reducer;
